<template>
  <div class="Base-Div">
    <div class="Feeds-macro-container">
      <div
        class="Feeds-user-container"
        v-for="(video, index) in userVideos"
        :key="index"
        :data-embed-url="video.embedURL"
        :data-index="index"
        :href="`#${index}`"
      >
        <div class="user-info">
          <img :src="video.profilePicture" alt="User Picture" class="custom-user-round-picture" />
          <a :href="`http://www.vulcano2k.com/user/${encodeURIComponent(video.profileName)}`" class="custom-user-profile-name">{{ video.profileName }}</a>
        </div>

        <div class="video-title-a">
          <h3>{{ video.videoTitle }}</h3>
        </div>

        <div class="video-wrapper">
          <video
            :src="video.previewURL"
            class="responsive-video preview"
            preload="auto"
            muted
            @contextmenu.prevent
            loop
            @dblclick="togglePlayPause(index)"
            @timeupdate="updateProgress(index)"
          ></video>
          
          <button @click="toggleMute(index)" class="mute-button">
            <i :class="isMuted[index] ? 'fas fa-volume-mute' : 'fas fa-volume-up'"></i>
          </button>
                    
          <div class="custom-progress-container">
            <div class="custom-progress-bar" @click="seekVideo($event, index)">
              <div class="custom-progress-handle" 
              @mousedown="startDrag($event, index)" 
              @touchstart="startDrag($event, index)">
              </div>
            </div>
          </div>
        </div>

        <div class="buttons-and-tags">
          <div class="feeds-interaction-buttons">
            <button class="custom-thumbs-up" @click="toggleThumbsUp(index)">
              <i :class="{'fas fa-thumbs-up': true, active: isThumbsUpActive(index)}"></i>
            </button>
            <button class="custom-thumbs-down" @click="toggleThumbsDown(index)">
              <i :class="{'fas fa-thumbs-down': true}"></i>
            </button>          
          </div>
          <div class="custom-Hash-container">
            <div class="custom-Hash-border">
              <div class="custom-hash-item" v-for="(hashtag, idx) in video.hashtags" :key="idx">
                <span>{{ hashtag }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // Import axios for making HTTP requests
import { ref, onMounted, nextTick, onUnmounted } from 'vue'; // Import Vue composition API functions

const BASE_URL = 'https://strapi.vulcano2k.com'; // Base URL for API requests

export default {
  name: 'UserProfile', // Component name
  setup() {
    // Define reactive variables using Vue's ref function
    const userVideos = ref([]); // Array to store user videos data
    const thumbsUpCounts = ref([]); // Array to store thumbs up counts for each video
    const videoRef = ref(null); // Reference to video element (currently not used)
    const currentPage = ref(1);
    const pageSize = ref(10);
    const isLoading = ref(false);
    let observer; // Variable for IntersectionObserver for preview videos
    let iframeObserver; // Variable for IntersectionObserver for embedded videos (iframes)

    const isDragging = ref(false);
    let draggingIndex = null;
    let wasPlaying = false;
    
     



    // Lifecycle hook that runs when the component is mounted
    onMounted(() => {      
      fetchUserData(); // Fetch user data when the component is mounted
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });



  const togglePlayPause = (index) => {
    const videoElements = document.querySelectorAll('.responsive-video.preview');
    const videoEl = videoElements[index];

    if (videoEl.paused) {
      videoEl.play();
    } else {
      videoEl.pause();
    }

    // Agora vamos adicionar o toggle da classe enlarged

  };

  const isMuted = ref([]); // Inicialmente vazio

// Alternar mudo/desmudo
  const toggleMute = (index) => {
    const videoElements = document.querySelectorAll(".responsive-video.preview");
    const videoEl = videoElements[index];

    if (videoEl) {
      videoEl.muted = !videoEl.muted; // Alterna o estado de mudo no DOM
      isMuted.value[index] = videoEl.muted; // Atualiza o estado reativo
    }
  };

    const updateProgress = (index) => {
      const videoElements = document.querySelectorAll('.responsive-video.preview');
      const videoEl = videoElements[index];

      const progressBars = document.querySelectorAll('.custom-progress-bar');
      const handles = document.querySelectorAll('.custom-progress-handle');

      if (videoEl && progressBars[index] && handles[index]) {
        const fraction = videoEl.currentTime / videoEl.duration;
        const percentage = fraction * 100;
        handles[index].style.left = percentage + '%';
      }
    };

    const seekVideo = (event, index) => {
      const videoElements = document.querySelectorAll('.responsive-video.preview');
      const videoEl = videoElements[index];
      const progressBar = event.currentTarget;

      // posição x do clique relativo a barra
      const rect = progressBar.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      const fraction = clickX / rect.width;
      const newTime = fraction * videoEl.duration;

      videoEl.currentTime = newTime;
    };

    const startDrag = (event, index) => {
      isDragging.value = true;
      draggingIndex = index;

      const handles = document.querySelectorAll('.custom-progress-handle');
      const handleEl = handles[draggingIndex];
      if (handleEl) {
        handleEl.classList.add('enlarged');
      }

      const videoElements = document.querySelectorAll('.responsive-video.preview');
      const videoEl = videoElements[index];

      // Se o vídeo não estiver pausado, significa que estava tocando
      if (!videoEl.paused) {
        wasPlaying = true;
        videoEl.pause();
      } else {
        wasPlaying = false;
      }

      event.preventDefault();

      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', stopDrag);
      document.addEventListener('touchmove', handleDrag, { passive: false });
      document.addEventListener('touchend', stopDrag);
    };

    const stopDrag = () => {
      if (!isDragging.value) return;
      isDragging.value = false;

      const videoElements = document.querySelectorAll('.responsive-video.preview');
      const videoEl = videoElements[draggingIndex];

      draggingIndex = null;

      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchmove', handleDrag);
      document.removeEventListener('touchend', stopDrag);

      // Se o vídeo estava tocando antes do drag, retomamos o play
      if (wasPlaying && videoEl) {
        videoEl.play();
      }

      // Redefine wasPlaying, caso queira
      wasPlaying = false;

      const handles = document.querySelectorAll('.custom-progress-handle');
        const handleEl = handles[draggingIndex];
        if (handleEl) {
          handleEl.classList.remove('enlarged');
}
    };

    const handleDrag = (event) => {
      if (!isDragging.value || draggingIndex === null) return;

      const videoElements = document.querySelectorAll('.responsive-video.preview');
      const videoEl = videoElements[draggingIndex];

      const progressBars = document.querySelectorAll('.custom-progress-bar');
      const progressBar = progressBars[draggingIndex];

      if (!videoEl || !progressBar) return;

      // Obter posição x
      const clientX = getClientX(event);
      if (clientX === null) return; // caso não tenha toque nem mouse
      
      event.preventDefault(); // Evita scroll em mobile enquanto arrasta

      const rect = progressBar.getBoundingClientRect();
      const clickX = clientX - rect.left;
      const fraction = Math.min(Math.max(clickX / rect.width, 0), 1);
      const newTime = fraction * videoEl.duration;
      videoEl.currentTime = newTime;

      const handles = document.querySelectorAll('.custom-progress-handle');
      const handleEl = handles[draggingIndex];
      handleEl.style.left = (fraction * 100) + '%';

      
    };

    const getClientX = (event) => {
      if (event.type.startsWith('touch')) {
        // Evento de toque
        return event.touches && event.touches.length > 0 ? event.touches[0].clientX : null;
      } else {
        // Evento de mouse
        return event.clientX;
      }
    };



    // Function to fetch user data from the API
    const fetchUserData = async () => {
        try {
          isLoading.value = true;
          const response = await axios.get(
            `${BASE_URL}/api/user-video-embeds`, {
              params: {
                'pagination[page]': currentPage.value,
                'pagination[pageSize]': pageSize.value,
                'sort': ['createdAt:desc'], 
                populate: {
                  user_profile: {
                    populate: {
                      ProfilePicture: {
                        fields: ['url']
                      }
                    }
                  },
                  hashtags: true,
                  VideoPreview: true,
                  
                },
                fields: ['createdAt', 'embedURL', 'VideoTitle'] 
              }
            }
          ); // Make GET request to API endpoint with query parameters to populate user profile, hashtags, and video preview

          const data = response.data.data; // Extract data from response

          console.log(response.data.data)

          // Process the data and store it in userVideos.value
          const newVideos = data.map((item) => ({
            profileName: getProfileName(item),
            profilePicture: getProfilePicture(item),
            userId: getUserId(item),
            hashtags: getHashtags(item),
            previewURL: getVideoPreviewURL(item),
            videoTitle: item.attributes.VideoTitle || 'Untitled',
            createdAt: new Date(item.attributes.createdAt),
          })).sort(() => Math.random() - 0.5);

          // Initialize thumbsUpCounts with zeros for each video
          userVideos.value.push(...newVideos);
          isMuted.value = userVideos.value.map(() => true);
          thumbsUpCounts.value = userVideos.value.map(() => 0);

          await nextTick(); // Wait for DOM to update

          setupIntersectionObserver(); // Stop/Start Preview
          

        } catch (error) {
          console.error('Error fetching user data:', error); // Log error if fetching data fails
        } finally {
          isLoading.value = false;
  }
    };

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight || document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 100 && !isLoading.value) {
        currentPage.value += 1;
        fetchUserData();
      }
    };




    // Function to get video preview URL
    const getVideoPreviewURL = (item) => {
      const previewUrl = item.attributes.VideoPreview.data[0].attributes.url;
      return `${BASE_URL}${previewUrl}`;
    };

    // Function to get profile name from item data
    const getProfileName = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes || 'W/O userProfile associated';
      return userProfile.ProfileName;
    };

    // Function to get profile picture URL
    const getProfilePicture = (item) => {
      const userProfile = item.attributes?.user_profile?.data?.attributes;
      if (userProfile && userProfile.ProfilePicture?.data?.attributes?.url) {
        return `${BASE_URL}${userProfile.ProfilePicture.data.attributes.url}`;
      }
      return ''; // Return an empty string if no profile picture URL is available
    };

    // Function to get user ID from item data
    const getUserId = (item) => item.attributes?.user_profile?.data?.id;

    // Function to get hashtags from item data
    const getHashtags = (item) =>
      item.attributes?.hashtags?.data.map((hashtag) => hashtag.attributes.HashCategorie) || [];

    // Function to toggle thumbs up for a video
    const toggleThumbsUp = (index) => {
      // Toggle between 0 and 1
      thumbsUpCounts.value[index] = thumbsUpCounts.value[index] === 0 ? 1 : 0;
    };

    // Function to check if thumbs up is active for a video
    const isThumbsUpActive = (index) => {
      return thumbsUpCounts.value[index] === 1;
    };

    // Function to handle thumbs down action (remove video from the list)
    const toggleThumbsDown = (index) => {
      const videoContainer = document.querySelectorAll('.Feeds-user-container')[index];

      if (videoContainer) {
        setTimeout(() => {
          // Remove the video data from the arrays
          userVideos.value.splice(index, 1);
          thumbsUpCounts.value.splice(index, 1);

          // Wait for the DOM to update
          nextTick(() => {
            // Disconnect the observers
            if (observer) observer.disconnect();
            if (iframeObserver) iframeObserver.disconnect();

            // Re-observe the current videos
            setupIntersectionObserver();
          });
        }, 100);
      }
    };

    // Function to set up Intersection Observers
    const setupIntersectionObserver = () => {
      // Observer for preview videos
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
            if (entry.isIntersecting) {
              // If the video is in the viewport
              if (video.paused) {                
                video.play(); // Play the video
              }
            } else {
              // If the video is out of the viewport
              video.pause(); // Pause the video
              video.currentTime = 0; // Reset to start
            }
          });
        },
        {
          threshold: [0], // Trigger when any part of the element is visible
        }
      );
      // Observe all preview videos
      document.querySelectorAll('.responsive-video.preview').forEach((video) => {
        observer.observe(video);
      });
    };

    


    // Return the reactive variables and functions to be used in the template
    return {
      userVideos,
      thumbsUpCounts,
      toggleThumbsUp,
      toggleThumbsDown,
      isThumbsUpActive,      
      videoRef,
      togglePlayPause,
      toggleMute,   // novo
      isMuted,
      updateProgress,
      seekVideo,
      startDrag,
       
    };
  },
};
</script>



<style>
  /* Um reset CSS básico */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

 
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .main-content {
    padding: 0;
    margin: 0; /* Se houver algum margin também */
  }






  .custom-controls {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.mute-button {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.custom-progress-container {
  width: 80%;
  margin-top: 10px; 
  position: relative;
}

.custom-progress-bar {
  width: 100%;
  height: 4px; /* diminuímos de 10px para 4px */
  background: #8d0f0f;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}

.custom-progress-handle {
  width: 14px;
  height: 14px;
  background: #5e1212;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0%; /* Começa no início */
  pointer-events: none; /* Para que o clique passe pela bola e atinja a barra */
}

.video-wrapper {
  width: 80%; /* Ajuste a largura para ser consistente com os outros elementos */
  height: auto; /* Preserva a relação de aspecto do vídeo */
  position: relative;
  overflow: hidden; /* Garante que o vídeo não ultrapasse o contêiner */
  display: flex; /* Garante que o conteúdo ocupe todo o espaço disponível */
  justify-content: center; /* Centraliza o vídeo dentro do contêiner */
  align-items: center;
}

/* Ícone de mute no canto superior direito */
.video-wrapper .mute-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0,0,0,0.5);
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  z-index: 10; /* para garantir que fique sobre o vídeo */
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.video-wrapper {
  width: 80%; /* Ajuste a largura conforme necessário */
  height: auto; /* Preserva a proporção do vídeo */
  position: relative;
  overflow: visible; /* Permite que elementos como a bolinha transbordem */
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-progress-container {
  width: 100%;
  position: absolute;
  bottom: -10px; /* Move o contêiner da barra abaixo do vídeo */
  left: 0;
}

.custom-progress-bar {
  width: 100%;
  height: 0; /* Invisível */
  background: transparent;
  cursor: pointer;
  position: relative;
}

.custom-progress-handle {
  width: 14px;
  height: 14px;
  background: #fff; /* Bolinha branca */
  border-radius: 50%;
  position: absolute;
  top: -7px; /* Alinha a bolinha com a base da barra */
  left: 0%; /* Começa no início */
  pointer-events: auto;
  z-index: 20; /* Acima de outros elementos */
}



.custom-progress-handle.enlarged {
  transform: translateY(-50%) scale(1.25);
}







  .Base-Div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;  
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
  }

  .Feeds-title {
    display: block;
    font-size: 40px;
    align-items: flex-start;
    width: 100%;
    font-family: 'Cinzel', serif;
  }

  .Feeds-macro-container { 
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    align-items: center;
    gap: 10px;
  }

  .Feeds-user-container {  /*Video + User Profile + Buttons + tags */
    display: flex;  
    width: 100%;  
    min-width: 200px;
    min-height: 350px;    
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    margin-top: 14px;
    margin-bottom: 20px;
    padding: 5px;    
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    box-sizing: border-box;
  }


  .user-info {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin-bottom: 10px;  
    flex-wrap: nowrap; /* Prevents vertical wrapping */
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
    max-width: 80%;
  }

  .custom-user-round-picture {
    align-items: left;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .custom-user-profile-name {
    font-family: Arial, sans-serif;
    color: #8B0000;
    font-size: 40px;
    text-decoration: none;
  }

  .video-title-a h3 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 80%;
    text-align: left;
    font-family: 'Playfair Display', serif;
    font-size: 32px;
    color: #696969;
    margin: 10px 0;

  }

  .video-title-a h3 {
    margin: 0;
    font-weight: bold;
  }

  .responsive-video.preview {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    display: block;
    
    border-radius: 15px;      
  }
  


  .buttons-and-tags{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content:left;
    align-items: center;
    padding-top: 18px;

  }
  .feeds-interaction-buttons {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0;  
    max-height: 80%;
  }

  .custom-thumbs-up,
  .custom-thumbs-down {
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .custom-thumbs-up-text {
    color: rgb(133, 44, 155);  /* Cor para "Sim" */
    font-weight: bold;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
  }


  .fas.fa-thumbs-up.active {
    color: rgb(0, 124, 182);
  }


  .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    max-height: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    overflow: hidden;
  }


  .custom-Hash-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    width: auto;
    border: 1px solid #ccc; /* Linha fina e cinza */
    box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura */
    flex-grow: 1;
  }

  .custom-Hash-border {
    display: flex;
    flex-wrap: wrap; /* Permite que os itens ocupem múltiplas linhas */
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 10px;
  }

  .custom-hash-item{
  text-decoration: none;
  background-color: #333;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  }

  .custom-hash-item a {
  color: white;
  text-decoration: none;
  }



/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {

  .Feeds-title {
   font-size: 20px;
  }

  .Feeds-user-container {  /*Video + User Profile + Buttons + tags */
  margin-top: 15px;
  padding: 0px;
  }

  .user-info {
   max-width: 100%;
  }

  .custom-user-round-picture {
    width: 45px;
    height: 45px;
  }

  .custom-user-profile-name {
    font-size: 25px;
  }

  .video-title-a h3{
  font-size: 18px;
  max-width: 100%;
  }

  .video-wrapper {
  width: 100%; /* Ajuste a largura para ser consistente com os outros elementos */

  }

  .responsive-video.preview {
    max-width: 100%;    
  }

  .responsive-video.embed {
    max-width: 100%;   
  }

  .buttons-and-tags{
    width: 100%;
    padding-top: 14px;
  }

  .custom-thumbs-up,
  .custom-thumbs-down {
    font-size: 12px;
  }

  .custom-Hash-container {
    max-width: 100%;
  }

  .custom-Hash-container a {
    font-size: 8px;
  }

  .custom-hash-item{
  font-size: 14px;
  }

  .Feeds-macro-container { 
  gap: 0px;
  }
  
  .feeds-interaction-buttons {
    max-width: 100%;
  }

  .custom-hash-item a {
    font-size: 14px;
  }

  .user-info {
    margin-bottom: 5px;  
  }

}
</style>