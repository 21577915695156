<template>
  <div class="page-container-unique">
    <div class="back-arrow-unique" @click="$router.push('/')">
      <span>Back</span> &#8592;
    </div>
    <h1 class="custom-h1">Copyright</h1>

    <h2 class="custom-h2">Disclaimer and Copyright Policy (with DMCA Reference)</h2>
    <p class="paragraph-unique">This website (<strong>Vukcano.com</strong>) does not host or store the full videos displayed. 
      All full-length content is sourced from third-party websites via publicly available embed links. 
      <strong>Vukcano.com</strong> does not claim any ownership or responsibility for the full content embedded from these third parties. 
      Any visual material presented as part of our service, such as previews or snapshots, is provided solely for informational or promotional purposes.</p>
    <p class="paragraph-unique">If you believe any material displayed on this website violates your intellectual property rights, 
      please follow the procedures outlined below for removal.</p>

    <h3 class="custom-h3">DMCA Takedown Procedure:</h3>
    <p class="paragraph-unique"><strong>Vukcano.com</strong> respects the intellectual property rights of third parties and complies with the 
      Digital Millennium Copyright Act (DMCA). If you believe that your copyrighted work has been posted on our website without proper authorization, 
      please submit a copyright infringement notice that includes the following information:</p>
    <ul>
      <li class="paragraph-unique">A description of the copyrighted work that you claim has been infringed.</li>
      <li class="paragraph-unique">The exact URL of the page where the allegedly infringing content is located.</li>
      <li class="paragraph-unique">Your contact information, including address, phone number, and email address.</li>
      <li class="paragraph-unique">A statement by you, made in good faith, that the use of the material in question is not authorized by the copyright owner, 
        its agent, or the law.</li>
      <li class="paragraph-unique">A statement that the information in the notification is accurate, and under penalty of perjury, 
        that you are the owner or are authorized to act on behalf of the owner of the copyright.</li>
      <li class="paragraph-unique">Your physical or electronic signature.</li>
    </ul>
    <p class="paragraph-unique">Please send your notice to: <strong>vukcano@protonmail.com</strong></p>
    <p class="paragraph-unique">Upon receiving a complete and valid copyright infringement notice, we will remove or disable access to the infringing content promptly 
      and notify the uploader or user who posted the content. 
      If the uploader or user believes the removal was made in error, they may submit a counter-notice. 
      Counter-notices must comply with the requirements of the DMCA and will be reviewed accordingly.</p>

    <h3 class="custom-h3">Advertising and Monetization:</h3>
    <p class="paragraph-unique">This website may use third-party advertising and affiliate programs as a source of revenue, including ad networks and partnerships 
      with content providers. All embedded videos on the site may contain logos and branding from the respective original third-party websites.</p>
  </div>
</template>

<script>


export default {
name: 'InfoCopyright',
};
</script>

<style>
.page-container-unique {
width: 80%;
margin: 0 auto;
padding: 40px 20px;
background-color: #343434;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px;
font-family: Arial, sans-serif;
}


.custom-h1 {
font-size: 76px;
color: #fff9f9;
text-align: center;
margin-bottom: 20px;
padding-top: 15px;
}

.custom-h2{
  font-size: 38px;
  color: #fff9f9;
  margin-bottom: 10px;
  margin-top: 60px; 
  margin-bottom: 30px;
}

.custom-h3{
  font-size: 38px;
  color: #fff9f9;
  margin-bottom: 10px;
  margin-top: 60px; 
}


.paragraph-unique {
font-size: 22px;
color: #bababa;
line-height: 1.6;
text-align: justify; /* Justifica o texto */
margin-bottom: 20px; 
}

ul {
list-style-type: disc;
margin-left: 20px;
color: #bababa;
font-size: 22px;
line-height: 1.6;
text-align: justify; /* Justifica o texto nas listas */
}

li {
margin-bottom: 10px;
text-align: justify; /* Justifica cada item da lista */
}

strong {
color: #fff9f9;
}

.back-arrow-unique {
display: flex;
align-items: center;
font-size: 18px;
font-weight: bold;
color: #00000095;
cursor: pointer;
transition: color 0.3s ease;
position: relative;
padding: 10px 0;
}

.back-arrow-unique span:first-child {
margin-right: 8px;
font-size: 24px;
transition: transform 0.3s ease;
}

.back-arrow-unique:hover {
color: hsl(0, 92%, 20%);
}

.back-arrow-unique:hover span:first-child {
transform: translateX(-5px);
}

.back-arrow-unique::before {
content: '';
position: absolute;
left: 0;
bottom: -5px;
width: 100%;
height: 2px;
opacity: 0;
transition: opacity 0.3s ease;
}

.back-arrow-unique:hover::before {
opacity: 1;
}


/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {

  .page-container-unique {
  width: 90%;
  padding-top: 8px;
  }

  .custom-h1 {
  font-size: 50px;
  padding-top: 0px;
  margin: 0px;
  }

  .custom-h2{
    margin-top: 30px; 
    font-size: 28px;
    margin-bottom: 28px; 
  }

  .custom-h3{
    padding-top: 0px;
    font-size: 28px;
  }

  .paragraph-unique {
  font-size: 18px;
  }

  .back-arrow-unique {
    color: hsl(0, 92%, 20%);
  }


}
</style>
