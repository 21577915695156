<template>
  <div class="categories">    
    <div class="category-body">
      <button class="scroll-button-left" @click="scrollLeft">&#8249;</button>
        <div class="category-list-container" ref="photoListContainer" >
          <div class="category-box" v-for="picture in pictures" :key="picture.id" @click="navigateToUserProfile(picture.userProfile.name)">
            <div class="category-picture">
              <div>
                <img :src="picture.url" :alt="picture.userProfile.name" class="category-circle-photo" />
              </div>
            </div>
            <div class="category-name">
              {{ picture.userProfile.name }}
            </div>
          </div>
        </div>
      <button class="scroll-button-right" @click="scrollRight">&#8250;</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const BASE_URL = 'https://strapi.vulcano2k.com'

export default {
  data() {
    return {
      pictures: []
    };
  },
  created() {
    this.fetchPictures();
  },
  methods: {
    async fetchPictures() {
      try {
        const response = await axios.get(`${BASE_URL}/api/user-profiles?populate=*`);
        
        if (response.data && response.data.data) {          
          this.pictures = response.data.data.map(item => {
            const userProfile = item.attributes;
            const profilePicture = userProfile.ProfilePicture?.data;
            if (profilePicture) {
              return {
                id: profilePicture.id,
                url: BASE_URL + profilePicture.attributes.url, // Construct full URL
                userProfile: {
                  id: item.id,
                  name: userProfile.ProfileName
                }
              };
            } else {
              return null;
            }
          })
          .filter(picture => picture !== null)
          .sort((a, b) => a.userProfile.name.localeCompare(b.userProfile.name)); // Ordena por nome
          
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching pictures:', error);
      }
    },
    navigateToUserProfile(profileName) {
      this.$router.push({ name: 'UserProfile', params: { id: profileName } });
    },
    scrollLeft() {
      const container = this.$refs.photoListContainer;
      container.scrollBy({
        top: 0,
        left: -600,
        behavior: 'smooth'
      });
    },
    scrollRight() {
      const container = this.$refs.photoListContainer;
      container.scrollBy({
        top: 0,
        left: 600,
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style>

.stories-title {
  font-size: 28px;
}

.categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;  
}

.category-body {
  display: flex;
  flex-direction: row;  
  align-items: center;
  width: 100%;  
}

.scroll-button-left  {
  position: flex;
  align-items: center;
  justify-content: center;
  height: 150px; /* Aumenta a altura do botão */
  width: 40px; /* Mantém a largura mais estreita */
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: rgb(237, 18, 18);
  font-size:90px;
  cursor: pointer; 
  padding-bottom: 30px;
}

.category-list-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow-x: scroll;  
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
}


.category-box {
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom : 10px;
  padding-left: 10px;
  flex-direction: column;
  align-items: center;
}

.category-box:first-child {
  padding-left: 0px;

}

.category-circle-photo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.category-name {
  text-align: center;
  color: #991111;
  font-size: 22px;
  margin-top: 0;
  padding: 0;
}

.scroll-button-right {
  position: flex;
  align-items: center;
  justify-content: center;
  height: 150px; /* Aumenta a altura do botão */
  width: 40px; /* Mantém a largura mais estreita */
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: rgb(237, 18, 18);
  font-size:90px;
  cursor: pointer; 
  padding-bottom: 30px;
}

/* media-------------------------------------------------------------------------------------------------------------------------------------- */

@media only screen and (max-width: 431px) {

  .category-circle-photo {
  width: 50px;
  height: 50px;
  }

  .category-name {
  font-size: 16px;
  }

  .category-body {
  max-height: 80px;
}




}
</style>
