<template>
  <div class="page-container">
    <div class="back-arrow" @click="$router.push('/')">
      <span>Back</span> &#8592;
    </div>
    <h1>What's New</h1>
    <p class="body-text"><strong>version 1.0.0</strong></p>
    <p class="body-text"> This is the first version of Vulcano2k. Stay tuned for more updates!.</p>
  </div>
</template>

<style>
.page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #343434;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 40px;
  color: #fff9f9;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 15px;
}

p {
  font-size: 1.2rem;
  color: #bababa;
  line-height: 1.6;
  text-align: center;
}

.body-text {
  text-align: left; /* Alinhamento à esquerda */
  color: #bababa;
}

.back-arrow {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #00000095;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  padding: 10px 0;
}

.back-arrow span:first-child {
  margin-right: 8px;
  font-size: 24px;
  transition: transform 0.3s ease;
}

.back-arrow:hover {
  color: hsl(0, 92%, 20%);
}

.back-arrow:hover span:first-child {
  transform: translateX(-5px);
}

.back-arrow::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.back-arrow:hover::before {
  opacity: 1;
}
</style>
